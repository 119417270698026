const theme = {
  colors: {
    background: '#fafafa',
    text: '#171312',
    primary: '#D00000',
    secondary: '#ffba08',
    accent: '#2797f5',
    gray: '#728294',
    white: '#fff',
    muted: '#dfdfdf'
  },
  fonts: {
    body: '"Source Sans Pro", system-ui, sans-serif',
    heading: '"IBM Plex Sans", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    '.5rem',
    '.75rem',
    '1.15rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '3rem',
  ],
  space: [
    0,
    '.5rem',
    '.8rem',
    '1rem',
    '2rem',
    '3rem',
    '5rem',
    '8rem',
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: '1.5',
    heading: '1.2',
  },
  shadows: {
    console: '3px 2px 0px 4px black',
    redConsole: '3px 2px 0px 4px #D00000'
  },
  texts: {
    heading: {
      fontFamily: "monospace",
      fontWeight: "heading",
      lineHeight: "heading",
    }
  },
  images: {
    fullWidth: {
      width: '100%',
    },
  },
  styles: {
    h1: {
      fontFamily: "monospace",
      fontWeight: "heading",
      lineHeight: "heading",
      backgroundColor: 'secondary',
      fontSize: 6,
      marginTop: 4,
      marginBottom: 3,
      padding: 0,
    },
    h2: {
      fontFamily: "monospace",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 5,
      marginTop: 4,
      marginBottom: 3,
      padding: 0,
    },
    h3: {
      fontFamily: "monospace",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 4,
      marginBottom: 3,
      padding: 0,
    },
    h4: {
      fontFamily: "monospace",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: 3,
      margin: 0,
      marginBottom: 2,
      padding: 0,
    },
    p: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 2,
      marginBottom: '1rem'
    },
    a: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: 'bold',
      color: 'accent',
      textDecoration: 'none',
      fontSize: 2,
    },
    ul: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 2,
      paddingLeft: 4
    }
  }
};

export default theme;